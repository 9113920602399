// custom typefaces
import 'typeface-montserrat';
import 'typeface-merriweather';
// Highlighting for code blocks
import 'prismjs/themes/prism.css';

// normalize CSS across browsers
import './src/normalize.css';
// custom CSS styles
import './src/style.css';
